import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { MyProductsComponent } from './my-products.component';
import { ProductModule } from '@shabic/product';
import {
  AlertModule,
  CarouselModule,
  LayoutModule,
  PaginationModule,
  SearchFormModule,
} from '@shabic/shared';
import { UiModule } from '@shabic/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlModule } from '@shabic/form-control';
import { IconModule } from '@shabic/icon';
import { AuthGuard, SupplierGuard } from '@shabic/core';
import { UtilsModule } from '@shabic/utils';

const routes: Route[] = [
  {
    path: 'my-products',
    component: MyProductsComponent,
    canActivate: [AuthGuard, SupplierGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ProductModule,
    LayoutModule,
    AlertModule,
    UiModule,
    TranslateModule,
    SearchFormModule,
    FormControlModule,
    PaginationModule,
    IconModule,
    UtilsModule,
    CarouselModule,
  ],
  declarations: [MyProductsComponent],
})
export class MyProductsModule {}
