import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorResponse, isErrorResponse } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';
import { GovApiService } from '../../services';

@Component({
  selector: 'shabic-gov-api-redirect',
  templateUrl: './gov-api-redirect.component.html',
  styleUrls: ['./gov-api-redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GovApiRedirectComponent implements OnInit {
  private _error = new BehaviorSubject<ApiErrorResponse | null>(null);
  private _loading = new BehaviorSubject<boolean>(true);

  error$ = this._error.asObservable();
  loading$ = this._loading.asObservable();

  constructor(
    private route: ActivatedRoute,
    private govApiService: GovApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const state = this.route.snapshot.queryParamMap.get('state');

    this.validate(state);
  }

  private validate(state: string | null) {
    this.govApiService.validate(state).subscribe(response => {
      if (isErrorResponse(response)) {
        this._loading.next(false);
        this._error.next(response.payload);
      } else {
        this.router.navigateByUrl('/');
      }
    });
  }
}
