import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ApiErrorResponse,
  ApiResponse,
  IProductPayload,
  isErrorResponse,
  Task,
} from '@shabic/models';
import { AddProductService, SnackBarService } from '@shabic/core';
import { switchMap, takeUntil, filter } from 'rxjs/operators';
import { interval, BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'shabic-add-product-form',
  templateUrl: './add-product-form.component.html',
  styleUrls: ['./add-product-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductFormComponent {
  private _product: Subject<ApiResponse<unknown>> = new Subject();
  private _parsing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  parsing$: Observable<boolean> = this._parsing.asObservable();
  request = (product: IProductPayload) => {
    this._parsing.next(true);
    this.service.addProduct(product).subscribe(response => {
      if (isErrorResponse(response)) {
        this._parsing.next(false);
        this._product.next(response);
      }

      if (response.payload instanceof Task) {
        this.pingParsing(response.payload.id);
      }
    });

    return this._product.asObservable();
  };

  constructor(
    private service: AddProductService,
    private router: Router,
    private snackBarService: SnackBarService
  ) {}

  private pingParsing(id: string): void {
    interval(1000)
      .pipe(
        switchMap(() => this.service.pingFileParsing(id)),
        takeUntil(this.parsing$.pipe(filter(val => val === false)))
      )
      .subscribe(response => {
        if (isErrorResponse(response)) {
          this._parsing.next(false);
          this._product.next(response);
        }

        if (response.payload instanceof Task) {
          const status = response.payload.status;

          if (status === 'ERROR' || status === 'COMPLETED') {
            this._parsing.next(false);

            if (status === 'ERROR') {
              this._product.next(
                new ApiResponse(
                  (response.payload.data as ApiErrorResponse[])[0]
                )
              );
            } else {
              this.snackBarService.openSnackBar(
                'message.product-parsed-successfully'
              );
              this._product.next(response);
              this.router.navigateByUrl('/my-products');
            }
          }
        }
      });
  }
}
