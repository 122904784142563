import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiErrorResponse, isErrorResponse } from '@shabic/models';
import { BehaviorSubject } from 'rxjs';
import { GovApiService } from '../../services';

@Component({
  selector: 'shabic-gov-api-login',
  templateUrl: './gov-api-login.component.html',
  styleUrls: ['./gov-api-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GovApiLoginComponent implements OnInit {
  private _error = new BehaviorSubject<ApiErrorResponse | null>(null);
  private _loading = new BehaviorSubject<boolean>(true);

  error$ = this._error.asObservable();
  loading$ = this._loading.asObservable();

  constructor(private govApiService: GovApiService, private router: Router) {}

  ngOnInit(): void {
    this.getLoginUrl();
  }

  private getLoginUrl() {
    this.govApiService.getLoginUrl().subscribe(response => {
      if (isErrorResponse(response)) {
        this._loading.next(false);
        this._error.next(response.payload);
      } else {
        location.href = response.payload;
      }
    });
  }
}
