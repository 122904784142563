<div *ngIf="(loading$ | async) === false; else loader">
  <shabic-gov-api-error
    *ngIf="error$ | async as errorResponse"
    [response]="errorResponse"
  ></shabic-gov-api-error>
</div>

<ng-template #loader>
  <div class="text-grey flex justify-center py-5">
    <shabic-spinner size="xl"></shabic-spinner>
  </div>
</ng-template>
