import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shabic/core';
import { GovApiComponent } from './gov-api.component';
import { GovApiLoginComponent, GovApiRedirectComponent } from './components';

const routes: Routes = [
  {
    path: 'gov-api',
    component: GovApiComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'login',
        component: GovApiLoginComponent,
      },
      {
        path: 'redirect',
        component: GovApiRedirectComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GovApiRoutingModule {}
