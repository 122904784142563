<shabic-animate>
  <section class="grid gap-5.5 py-3 desktop:py-12">
    <header class="max-w-[300px] text-center mx-auto">
      <shabic-headline variant="h2">
        <span class="text-base desktop:text-xl">
          {{ 'common.select-product-options' | translate }}
        </span>
      </shabic-headline>
    </header>

    <div
      class="flex flex-col desktop:flex-row gap-3 desktopgap-4 desktop:justify-center"
    >
      <a
        [routerLink]="option.route"
        class="flex flex-col items-center gap-5.5 py-7 px-5 rounded-2xl w-full desktop:max-w-[300px] text-center hover:shadow-primary-100 bg-white active:shadow-none border-2 border-grey-350 transition-shadow relative z-10 hover:z-1"
        *ngFor="let option of options"
      >
        <ng-template [shabicIcon]="option.icon"></ng-template>
        <div class="grid gap-[6px]">
          <shabic-headline variant="h4">
            <span class="font-semibold">{{ option.title | translate }}</span>
          </shabic-headline>
          <shabic-sub-headline>
            {{ option.subTitle | translate }}
          </shabic-sub-headline>
        </div>
      </a>
    </div>
  </section>
</shabic-animate>
