import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { ForgotPasswordDialogData } from '@shabic/models';

@Component({
  selector: 'shabic-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private eventBrokerService: EventBrokerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    const data = this.route.snapshot.queryParamMap.get('data');

    if (token && data) {
      this.eventBrokerService.emit(
        new AppEvent<ForgotPasswordDialogData>(
          EAppEvent.OpenForgotPasswordDialog,
          { token, data }
        )
      );
    }

    this.router.navigateByUrl('/search');
  }
}
