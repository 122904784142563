import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  ENV,
  IEnvironment,
} from '@shabic/models';
import { of, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GovApiService extends ApiClient {
  override readonly url: string = '/api-user';

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    @Inject(ENV) env: IEnvironment
  ) {
    super(env);
  }

  getLoginUrl() {
    const locale = this.translateService.currentLang;

    // TODO: implement login url receiving
    return of('http://gov-api.login').pipe(
      //Remove delay
      delay(2000),

      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  validate(state: string | null) {
    if (!state) {
      return this.getErrorRequest().pipe(
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
    }

    // TODO: implement code validation and user login
    return of('Valid').pipe(
      //Remove delay
      delay(2000),

      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  // For testing
  private getErrorRequest() {
    return throwError({ error: { detail: 'Some Gov Api Error' } });
  }
}
