import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddProductRoutingModule } from './add-product-routing.module';
import { AddProductComponent } from './add-product.component';
import { DropzoneModule, LayoutModule } from '@shabic/shared';
import { ProductModule } from '@shabic/product';
import {
  AddProductFormComponent,
  AddProductOptionsComponent,
  AddProductExcelFormComponent,
  AddProductExcelListComponent,
} from './components';
import { UiModule } from '@shabic/ui';
import { IconModule } from '@shabic/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlModule } from '@shabic/form-control';
import { UtilsModule } from '@shabic/utils';

@NgModule({
  declarations: [
    AddProductComponent,
    AddProductFormComponent,
    AddProductOptionsComponent,
    AddProductExcelFormComponent,
    AddProductExcelListComponent,
  ],
  imports: [
    CommonModule,
    AddProductRoutingModule,
    LayoutModule,
    ProductModule,
    UiModule,
    IconModule,
    TranslateModule,
    FormControlModule,
    IconModule,
    DropzoneModule,
    UtilsModule,
  ],
})
export class AddProductModule {}
