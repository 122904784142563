import { HttpClientModule } from '@angular/common/http';
import ar from '@angular/common/locales/ar';
import fr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@shabic/core';
import { DialogModule } from '@shabic/dialog';
import { ENV } from '@shabic/models';
import { SignUpModule } from '@shabic/sign-up';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { ProfileModule } from '@shabic/profile';
import { AddProductModule } from '@shabic/add-product';
import { MyProductsModule } from '@shabic/my-products';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LayoutModule } from '@shabic/shared';
import { getCurrentLocale } from '@shabic/utils';
import { GovApiModule } from '@shabic/gov-api';
import { ForgotPasswordComponent } from './components';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

registerLocaleData(ar);
registerLocaleData(fr);

@NgModule({
  declarations: [AppComponent, ForgotPasswordComponent],
  imports: [
    BrowserModule,
    SignUpModule,
    HttpClientModule,
    CoreModule,
    DialogModule,
    ProfileModule,
    AddProductModule,
    MyProductsModule,
    GovApiModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    MatSnackBarModule,
    LayoutModule,
  ],
  providers: [
    {
      provide: ENV,
      useValue: environment,
    },
    { provide: MAT_DATE_LOCALE, useValue: getCurrentLocale() },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
