<shabic-animate classes="desktop:py-10 py-3">
  <div class="max-w-[570px] mx-auto">
    <shabic-headline variant="h2" align="center">
      <span class="text-base desktop:text-xl">
        {{ 'page.product-excel-form' | translate }}
      </span>
    </shabic-headline>

    <shabic-sub-headline>
      {{ 'page.product-excel-form.description' | translate }}
    </shabic-sub-headline>
  </div>

  <div class="mt-7 max-w-[600px] mx-auto">
    <p class="font-semibold text-sm text-primary mb-4 text-center">
      {{ 'common.download-excel' | translate }}
    </p>

    <div
      class="flex flex-col desktop:flex-row gap-3 desktop:items-center desktop:justify-between rounded-2xl border-2 border-grey-350 p-3"
    >
      <div class="flex items-center gap-3">
        <span class="text-secondary">
          <ng-template shabicIcon="icon-excel-large-mono"></ng-template>
        </span>

        <strong class="text-sm text-primary font-semibold">
          Products-Template.xlsx
        </strong>
      </div>

      <a
        href="https://shabic-dev.s3.eu-central-1.amazonaws.com/asserts/Template+for+product+upload_final+(1).xlsx"
        download
        type="button"
        shabicButton="primary"
      >
        {{ 'button.download' | translate }}
      </a>
    </div>

    <p
      class="font-semibold text-sm text-primary mt-5.5 desktop:mt-7 mb-4 text-center"
    >
      {{ 'common.drag-and-drop-excel' | translate }}
    </p>

    <shabic-dropzone
      [disabled]="parsing$ | async"
      (changed)="onFileSelected($event)"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    >
      <p
        class="text-grey text-sm font-semibold my-6"
        [innerHTML]="'common.drag-and-drop' | translate | safeHtml"
      ></p>
    </shabic-dropzone>
  </div>
</shabic-animate>

<ng-template #loading>
  <shabic-spinner size="xl"></shabic-spinner>
</ng-template>
