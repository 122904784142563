<shabic-animate>
  <section
    class="grid gap-5.5 desktop:max-w-[800px] mx-auto desktop:mt-5.5 mb-8"
  >
    <header>
      <shabic-headline variant="h2" align="center">
        <span class="text-base text-xl">
          {{ 'page.add-product-manually' | translate }}
        </span>
      </shabic-headline>

      <shabic-sub-headline>
        {{ 'page.add-product-manually.description' | translate }}
      </shabic-sub-headline>
    </header>

    <shabic-product-form [request]="request"></shabic-product-form>
  </section>
</shabic-animate>
