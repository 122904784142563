import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@shabic/core';
import { ForgotPasswordComponent } from './components';

const routes: Routes = [
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'catalog',
    loadChildren: () =>
      import('./catalog/catalog.module').then(m => m.CatalogModule),
  },
  {
    path: 'cart',
    canLoad: [AuthGuard],
    loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
  },
  {
    path: 'orders',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'supplier',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./supplier/supplier.module').then(m => m.SupplierModule),
  },
  {
    path: 'my-quotas',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./my-quotas/my-quotas.module').then(m => m.MyQuotasModule),
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: '**',
    redirectTo: 'search',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
