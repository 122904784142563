<shabic-animate>
  <shabic-headline variant="h2" class="block mt-3 mb-4">
    <span class="text-base desktop:text-xl">
      {{ 'page.excel-list' | translate }}
    </span>
  </shabic-headline>

  <div class="overflow-x-auto mb-10 desktop:mb-0">
    <table>
      <thead>
        <tr class="border-y border-grey-300">
          <th
            class="text-[9px] text-primary font-semibold uppercase p-3 ltr:text-left rtl:text-right min-w-[150px] leading-3"
            *ngFor="let column of columns"
          >
            {{ column.label | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of products$ | async; odd as odd">
          <ng-container *ngIf="isProduct(row)">
            <tr *ngIf="row" [class.odd]="odd">
              <td
                class="px-3 py-[20px] text-[13px] text-primary"
                [class.font-semibold]="column.bold"
                [class.bg-grey-350]="odd"
                [class.ltr:first:rounded-l-2xl]="odd"
                [class.ltr:last:rounded-r-2xl]="odd"
                [class.rtl:first:rounded-r-2xl]="odd"
                [class.rtl:last:rounded-l-2xl]="odd"
                *ngFor="let column of columns"
              >
                <span *ngIf="!row.hasError(column.key)">
                  {{ column.value(row) }}
                </span>

                <ng-container *ngIf="row.hasError(column.key)">
                  <ul>
                    <li
                      class="text-red font-normal"
                      *ngFor="let error of row.getErrors(column.key)"
                    >
                      {{ 'error.' + error | translate }}
                    </li>
                  </ul>
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="row && row.incorrect">
              <td [attr.colspan]="columns.length + 1">
                <div class="text-red py-3 flex gap-1 items-center">
                  <ng-template shabicIcon="alert"></ng-template>

                  <span class="text-xs font-medium">
                    {{ 'error.some-fields-incorrect' | translate }}
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="isProductError(row)">
            <tr *ngIf="row && row.incorrect" [class.odd]="odd">
              <td [attr.colspan]="columns.length + 1">
                <div class="text-red py-3 flex gap-1 items-center">
                  <ng-template shabicIcon="alert"></ng-template>

                  <span class="text-xs font-medium">
                    {{ row.error }}
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div
    class="fixed left-0 bottom-0 right-0 desktop:static bg-main-footer desktop:bg-none grid gap-4 px-[20px] desktop:px-0 pt-5 desktop:pt-4 pb-3 desktop:pb-5"
  >
    <div>
      <a
        routerLink="../excel-form"
        type="button"
        shabicButton="primary"
        class="w-full desktop:w-auto"
      >
        {{ 'button.re-upload-files' | translate }}
      </a>
    </div>
  </div>
</shabic-animate>
