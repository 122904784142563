<shabic-animate classes="desktop:py-3 grid gap-5.5">
  <div *ngIf="hasAlert$ | async">
    <shabic-alert (closed)="onHideAlert()"></shabic-alert>
  </div>

  <ng-container *ngIf="list$ | async as list">
    <section class="grid gap-3 desktop:gap-4">
      <header
        class="flex flex-col desktop:flex-row gap-3 desktop:justify-between desktop:items-center"
      >
        <div
          class="flex gap-2 items-center justify-between desktop:justify-start"
        >
          <shabic-headline variant="h4">
            <span class="font-semibold">
              {{ 'navigation.my-products' | translate }}
            </span>
          </shabic-headline>
          <span
            class="text-semibold text-xs text-grey px-2 py-1 rounded-3xl border border-grey-350 desktop:bg-grey-350 desktop:border-0"
          >
            {{ 'status.products-amount' | translate: { amount: list.total } }}
          </span>
        </div>

        <div class="desktop:max-w-[400px] grow">
          <shabic-search-form
            [hasSearchButton]="false"
            (search)="onSearch($event)"
          ></shabic-search-form>
        </div>
      </header>

      <ng-container *ngIf="loading$ | async; else listContent">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
      </ng-container>

      <ng-template #listContent>
        <div class="grid desktop:grid-cols-cards gap-4">
          <div
            class="relative border border-grey-350 rounded-3xl p-3 desktop:py-5 desktop:px-5.5"
            *ngFor="let product of list.content"
          >
            <div class="grid gap-4 desktop:gap-5.5">
              <div class="flex justify-center pt-1 px-4 desktop:p-0">
                <shabic-carousel
                  [product]="product"
                  size="md"
                ></shabic-carousel>
              </div>

              <div class="grid gap-0.5 place-content-center">
                <shabic-headline variant="h4" align="center">
                  <span class="font-semibold">
                    {{ product.name | currentLang | async }}
                  </span>
                </shabic-headline>

                <p class="text-xs font-semibold text-grey text-center -mt-0.5">
                  {{ product.brand }}
                </p>

                <div class="text-center">
                  <span class="mt-1" shabicBadge="secondary">
                    {{ product.category?.name | currentLang | async }}
                  </span>
                </div>
              </div>
            </div>

            <hr class="text-grey-350 my-4" />

            <div class="flex justify-between gap-2">
              <div class="flex flex-col gap-0.5 font-semibold">
                <span class="text-grey uppercase text-[10px] rtl:text-right">
                  {{
                    'common.unit-price-currency'
                      | translate
                        : { currency: (currentCurrency$ | async)?.value }
                  }}
                </span>
                <span class="text-primary text-sm rtl:text-right">
                  {{
                    product.priceFirst | convert: product.currency:false | async
                  }}
                </span>
              </div>

              <div class="flex flex-col gap-0.5 font-semibold">
                <span class="text-grey uppercase text-[10px] rtl:text-right">
                  {{
                    'common.vat-currency'
                      | translate
                        : { currency: (currentCurrency$ | async)?.value }
                  }}
                </span>
                <span class="text-primary text-sm rtl:text-right">
                  {{
                    product.vat || 0 | convert: product.currency:false | async
                  }}
                </span>
              </div>

              <div class="flex flex-col gap-0.5 font-semibold">
                <span class="text-grey uppercase text-[10px] rtl:text-right">
                  {{
                    'common.discount-currency'
                      | translate
                        : { currency: (currentCurrency$ | async)?.value }
                  }}
                </span>
                <span class="text-primary text-sm rtl:text-right">
                  {{ 0 | convert: product.currency:false | async }}
                </span>
              </div>

              <div class="flex flex-col gap-0.5 font-semibold">
                <span class="text-grey uppercase text-[10px] rtl:text-right">
                  {{
                    'common.total'
                      | translate
                        : { currency: (currentCurrency$ | async)?.value }
                  }}
                </span>
                <span class="text-secondary text-sm rtl:text-right">
                  {{
                    product.totalPrice | convert: product.currency:false | async
                  }}
                </span>
              </div>
            </div>

            <hr class="text-grey-350 mt-4 mb-3" />
            <div class="grid gap-1 w-full">
              <button
                shabicButton="primary"
                type="button"
                class="gap-1 w-full"
                (click)="onEdit(product)"
              >
                <ng-template shabicIcon="edit"></ng-template>
                {{ 'button.edit' | translate }}
              </button>

              <div class="grid grid-cols-2 gap-1">
                <button
                  shabicButton="grey"
                  *ngIf="product.isStatus('ACTIVE')"
                  class="gap-1"
                  type="button"
                  [disabled]="product.isDeactivated"
                  (click)="onDeactivate(product)"
                >
                  <span class="text-grey">
                    <ng-template shabicIcon="icon-close-circle"></ng-template>
                  </span>
                  {{ 'button.deactivate' | translate }}
                </button>

                <button
                  shabicButton="green"
                  *ngIf="product.isStatus('DISABLED_BY_SUPPLIER')"
                  class="gap-1"
                  type="button"
                  (click)="onActivate(product)"
                >
                  <ng-template shabicIcon="check"></ng-template>
                  {{ 'button.activate' | translate }}
                </button>
                <button
                  shabicButton="danger-filled"
                  class="gap-1"
                  type="button"
                  [disabled]="product.isStatus('DISABLED_BY_SUPPLIER')"
                  (click)="onDelete(product)"
                >
                  <ng-template shabicIcon="delete"></ng-template>
                  {{ 'button.delete' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="mt-6 mb-10">
        <shabic-pagination
          [pages]="list.pages"
          [page]="list.page"
          (nextPage)="onChangePage($event)"
        ></shabic-pagination>
      </div>
    </section>
  </ng-container>
</shabic-animate>

<ng-template #loader>
  <div class="text-grey flex justify-center py-5">
    <shabic-spinner size="xl"></shabic-spinner>
  </div>
</ng-template>
