import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  CompanyService,
  CurrencyService,
  ProductService,
  SnackBarService,
} from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  Company,
  IListParams,
  isErrorResponse,
  List,
  ListComponent,
  ListProduct,
  Product,
  ProductFormDialogData,
  ProductSearchContent,
} from '@shabic/models';
import { BehaviorSubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

interface ISearchParams {
  companyIds: number[];
  search?: string;
  size: number;
  page: number;
}

@Component({
  selector: 'shabic-my-products',
  templateUrl: './my-products.component.html',
  styleUrls: ['./my-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyProductsComponent
  extends ListComponent<ListProduct, ProductSearchContent>
  implements OnInit, OnDestroy
{
  private _hasAlert = new BehaviorSubject<boolean>(true);

  hasAlert$ = this._hasAlert.asObservable();
  currentCurrency$ = this.currencyService.currentCurrency$;

  constructor(
    private productService: ProductService,
    private companyService: CompanyService,
    private eventBrokerService: EventBrokerService,
    private snackbarService: SnackBarService,
    private currencyService: CurrencyService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  ngOnInit(): void {
    this.companyService.getMyCompany().subscribe(response => {
      if (response.payload instanceof Company) {
        this.setParams({
          ...List.initParams,
          companyIds: [response.payload.id],
        });
      }
    });

    this.initList(
      switchMap(params =>
        this.productService
          .getProducts(params as ISearchParams)
          .pipe(map(response => response.payload))
      )
    );
  }

  onChangeParam(params: IListParams): void {
    this.setParams(params);
  }

  onChangePage(page: number): void {
    this.changeParam('page', page);
  }

  onSearch(query: string): void {
    this.changeParam('search', query);
  }

  onEdit(product: Product) {
    this.eventBrokerService.emit(
      new AppEvent<ProductFormDialogData>(EAppEvent.OpenProductFormDialog, {
        product,
        onUpdate: () => this.reload(),
      })
    );
  }

  onDeactivate(product: Product) {
    if (product.isDeactivated) {
      return;
    }

    this.productService
      .changeStatus(product, 'DISABLED_BY_SUPPLIER')
      .subscribe(response => {
        if (!isErrorResponse(response)) {
          this.snackbarService.openSnackBar('status.status-will-change');
        }
      });
  }

  onActivate(product: Product) {
    this.productService.changeStatus(product, 'ACTIVE').subscribe(response => {
      if (!isErrorResponse(response)) {
        this.snackbarService.openSnackBar('status.status-will-change');
      }
    });
  }

  onDelete(product: Product) {
    if (product.isDeactivated) {
      return;
    }

    this.productService
      .changeStatus(product, 'TO_DELETE')
      .subscribe(response => {
        if (!isErrorResponse(response)) {
          this.snackbarService.openSnackBar('status.status-will-change');
        }
      });
  }

  onHideAlert() {
    this._hasAlert.next(false);
  }
}
