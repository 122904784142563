import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Icon } from '@shabic/icon';

type Option = {
  title: string;
  subTitle: string;
  icon: Icon;
  route: string;
};

@Component({
  selector: 'shabic-add-product-options',
  templateUrl: './add-product-options.component.html',
  styleUrls: ['./add-product-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductOptionsComponent {
  readonly options: Option[] = [
    {
      title: 'common.upload-products-manually',
      subTitle: 'common.upload-products-manually.description',
      icon: 'manual',
      route: 'form',
    },
    {
      title: 'common.import-from-excel',
      subTitle: 'common.import-from-excel.description',
      icon: 'excel-large',
      route: 'excel-form',
    },
  ];
}
