import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IProductPayload, isErrorResponse, Task } from '@shabic/models';
import {
  BehaviorSubject,
  interval,
  Observable,
  switchMap,
  takeUntil,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { AddProductService, SnackBarService } from '@shabic/core';

@Component({
  selector: 'shabic-add-product-excel-form',
  templateUrl: './add-product-excel-form.component.html',
  styleUrls: ['./add-product-excel-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductExcelFormComponent {
  private _parsing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  parsing$: Observable<boolean> = this._parsing.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: AddProductService,
    private snackBarService: SnackBarService
  ) {}

  showList(data: Task<IProductPayload>): void {
    this.router.navigate(['..', 'excel-list'], {
      relativeTo: this.route,
      state: {
        task: data,
      },
    });
  }

  onFileSelected(files: any[]): void {
    this._parsing.next(true);
    this.service.parseFile(files[0]).subscribe(response => {
      if (isErrorResponse(response)) {
        this._parsing.next(false);
      }

      if (response.payload instanceof Task) {
        this.pingParsing(response.payload.id);
      }
    });
  }

  private pingParsing(id: string): void {
    interval(1000)
      .pipe(
        switchMap(() => this.service.pingFileParsing(id)),
        takeUntil(this.parsing$.pipe(filter(val => val === false)))
      )
      .subscribe(response => {
        if (isErrorResponse(response)) {
          this._parsing.next(false);
        }

        if (response.payload instanceof Task) {
          const status = response.payload.status;

          if (status === 'ERROR') {
            this._parsing.next(false);
            this.showList(response.payload);
          }

          if (status === 'COMPLETED') {
            this.snackBarService.openSnackBar(
              'message.products-parsed-successfully'
            );
            this._parsing.next(false);
            this.router.navigateByUrl('/my-products');
          }
        }
      });
  }
}
