import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriesService } from '@shabic/core';
import { IProductColumn, productColumns } from '@shabic/data';
import {
  ApiErrorResponse,
  Category,
  ExcelProduct,
  ExcelProductError,
  IProductPayload,
  Product,
  Task,
} from '@shabic/models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'shabic-add-product-excel-list',
  templateUrl: './add-product-excel-list.component.html',
  styleUrls: ['./add-product-excel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductExcelListComponent {
  private _products = new BehaviorSubject<(ExcelProduct | ExcelProductError)[]>(
    []
  );
  readonly columns: IProductColumn[] = productColumns;
  readonly errorFields: Record<string, string[]> = {};
  rows?: Array<ExcelProduct | null>;
  categories?: Category[];

  products$ = this._products.asObservable();

  constructor(
    private router: Router,
    private categoriesService: CategoriesService
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state;

    if (state && state['task']) {
      this.categoriesService.updateCategories().subscribe(response => {
        if (Array.isArray(response.payload)) {
          this.categories = response.payload;

          this._products.next(this.parseTask(state['task']));
        }
      });
    }
  }

  isProduct(el: unknown): el is ExcelProduct {
    return el instanceof ExcelProduct;
  }

  isProductError(el: unknown): el is ExcelProductError {
    return el instanceof ExcelProductError;
  }

  private parseTask(
    task: Task<IProductPayload>
  ): Array<ExcelProduct | ExcelProductError> {
    return (task.data as ApiErrorResponse[]).map(el => {
      const model: IProductPayload = el.model as IProductPayload;
      const message = el.message || 'Error';

      if (model) {
        return new ExcelProduct(
          new Product(model, this.categories),
          el.fieldErrors
        );
      } else {
        return new ExcelProductError(message);
      }
    });
  }
}
