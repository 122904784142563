<shabic-animate>
  <section class="flex flex-col gap-3 items-center mb-6">
    <div class="text-red">
      <ng-template shabicIcon="alert" size="lg"></ng-template>
    </div>

    <div class="text-primary text-center">
      {{ message }}
    </div>

    <a routerLink="/" class="text-secondary text-lg hover:underline">
      {{ 'button.back' | translate }}
    </a>
  </section>
</shabic-animate>
