import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, SupplierGuard } from '@shabic/core';
import { AddProductComponent } from './add-product.component';
import {
  AddProductExcelFormComponent,
  AddProductExcelListComponent,
  AddProductFormComponent,
  AddProductOptionsComponent,
} from './components';

const routes: Routes = [
  {
    path: 'add-product',
    component: AddProductComponent,
    canActivate: [AuthGuard, SupplierGuard],
    children: [
      {
        path: '',
        component: AddProductOptionsComponent,
      },
      { path: 'form', component: AddProductFormComponent },
      { path: 'excel-form', component: AddProductExcelFormComponent },
      { path: 'excel-list', component: AddProductExcelListComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AddProductRoutingModule {}
