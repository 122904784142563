import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GovApiComponent } from './gov-api.component';
import { GovApiRoutingModule } from './gov-api-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@shabic/ui';
import {
  GovApiErrorComponent,
  GovApiLoginComponent,
  GovApiRedirectComponent,
} from './components';
import { IconModule } from '@shabic/icon';

@NgModule({
  imports: [
    CommonModule,
    GovApiRoutingModule,
    TranslateModule,
    UiModule,
    IconModule,
  ],
  declarations: [
    GovApiComponent,
    GovApiRedirectComponent,
    GovApiErrorComponent,
    GovApiLoginComponent,
  ],
})
export class GovApiModule {}
