import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ApiErrorResponse } from '@shabic/models';

@Component({
  selector: 'shabic-gov-api-error',
  templateUrl: './gov-api-error.component.html',
  styleUrls: ['./gov-api-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GovApiErrorComponent {
  @Input() response!: ApiErrorResponse;

  get message() {
    return this.response.message;
  }
}
